<template>
  <PageTemplate light>
		<Section color="primary" size="small" full>
			<Stack width="100%" justify="center">
				<Stack width="95%">
					<Slider time="1000000" height="600px" type="nav">
						<SliderContainer>
							<Grid>
								<GridContainer size="12 6@md">
									<Stack visibility="hide show@md">
										<Images width="100%" height="550px" image="theme-wm/home1.jpg" />
									</Stack>
									<Stack width="100%" justify="center" visibility="show hide@md">
										<Images width="300px" height="200px" image="theme-wm/home1.jpg" />
									</Stack>
								</GridContainer>
								<GridContainer size="12 5@md">
									<Stack height="100%" justify="center" align="center left@md" direction="column">
										<Heading size="6" uppercase whiteout>Discover Our Ideas</Heading>
										<Heading size="1" whiteout>Great Steps For Success</Heading>
										<Stack direction="column" align="center left@md" space="3">
											<Paragraph feature whiteout>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Paragraph>
											<Button label="View More" color="light" />
										</Stack>
									</Stack>
								</GridContainer>
							</Grid>
						</SliderContainer>
						<SliderContainer>
							<Grid>
								<GridContainer size="12 6@md">
									<Stack visibility="hide show@md">
										<Images width="100%" height="550px" image="theme-wm/home5.jpg" />
									</Stack>
									<Stack width="100%" justify="center" visibility="show hide@md">
										<Images width="300px" height="200px" image="theme-wm/home5.jpg" />
									</Stack>
								</GridContainer>
								<GridContainer size="12 5@md">
									<Stack height="100%" justify="center" align="center left@md" direction="column">
										<Heading size="6" uppercase whiteout>Discover Our Ideas</Heading>
										<Heading size="1" whiteout>Plan For The Future</Heading>
										<Stack direction="column" align="center left@md" space="3">
											<Paragraph feature whiteout>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Paragraph>
											<Button label="View More" color="light" />
										</Stack>
									</Stack>
								</GridContainer>
							</Grid>
						</SliderContainer>
						<SliderContainer>
							<Grid>
								<GridContainer size="12 6@md">
									<Stack visibility="hide show@md">
										<Images width="100%" height="550px" image="theme-wm/home6.jpg" />
									</Stack>
									<Stack width="100%" justify="center" visibility="show hide@md">
										<Images width="300px" height="200px" image="theme-wm/home6.jpg" />
									</Stack>
								</GridContainer>
								<GridContainer size="12 5@md">
									<Stack height="100%" justify="center" align="center left@md" direction="column">
										<Heading size="6" uppercase whiteout>Discover Our Ideas</Heading>
										<Heading size="1" whiteout>We'll Help You Get There</Heading>
										<Stack direction="column" align="center left@md" space="3">
											<Paragraph feature whiteout>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Paragraph>
											<Button label="View More" color="light" />
										</Stack>
									</Stack>
								</GridContainer>
							</Grid>
						</SliderContainer>
					</Slider>
				</Stack>
			</Stack>
		</Section>
		<Section>
			<Grid>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" align="left" space="2">
						<Icon icon="verified" size="large" color="dark" />
						<Heading size="5">Strategy Work</Heading>
						<Paragraph>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed.</Paragraph>
					</Stack>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" align="left" space="2">
						<Icon icon="emoji_objects" size="large" color="dark" />
						<Heading size="5">Pricing Function</Heading>
						<Paragraph>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed.</Paragraph>
					</Stack>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" align="left" space="2">
						<Icon icon="poll" size="large" color="dark" />
						<Heading size="5">Management Team</Heading>
						<Paragraph>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed.</Paragraph>
					</Stack>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" align="left" space="2">
						<Icon icon="language" size="large" color="dark" />
						<Heading size="5">Full Optimization</Heading>
						<Paragraph>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed.</Paragraph>
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
		<Section image="theme-wm/marble.jpg">
			<Grid>
				<GridContainer size="12 7@md">
					<Card size="xlarge">
						<CardSection>
							<Heading size="6" uppercase>What we do</Heading>
							<Heading size="3">Build a Better Future for Your Company</Heading>
							<Paragraph>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.</Paragraph>
							<Stack direction="column" align="left" space="3">
								<Paragraph>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.</Paragraph>
								<Button label="View More" color="secondary" />
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
				<GridContainer size="12 5@md">
					<Stack visibility="hide show@md">
						<Images width="100%" height="100%" image="theme-wm/home2.jpg" />
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
		<Section color="light">
			<Heading size="2">Latest News from the Industry</Heading>
			<Grid>
				<GridContainer size="12 6@sm 3@md">
					<Link url="/windermere/post">
						<Stack direction="column" align="left" space="2">
							<Images width="100%" height="300px" image="theme-wm/blog1.jpg" />
							<Heading size="6" uppercase>CONSULTING / JULY 27, 2021</Heading>
						</Stack>
						<Heading size="5">The fastest way to grow your business with us</Heading>
					</Link>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Link url="/windermere/post">
						<Stack direction="column" align="left" space="2">
							<Images width="100%" height="275px" image="theme-wm/blog2.jpg" />
							<Heading size="6" uppercase>CONSULTING / JULY 13, 2021</Heading>
						</Stack>
						<Heading size="5">Hiring the best consultants to help you grow business</Heading>
					</Link>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Link url="/windermere/post">
						<Stack direction="column" align="left" space="2">
							<Images width="100%" height="340px" image="theme-wm/blog3.jpg" />
							<Heading size="6" uppercase>CONSULTING / JULY 8, 2021</Heading>
						</Stack>
						<Heading size="5">Process of creating a strong, positive perception</Heading>
					</Link>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Link url="/windermere/post">
						<Stack direction="column" align="left" space="2">
							<Images width="100%" height="200px" image="theme-wm/blog5.jpg" />
							<Heading size="6" uppercase>CONSULTING / JULY 1, 2021</Heading>
						</Stack>
						<Heading size="5">Process of giving a meaning to organization</Heading>
					</Link>
				</GridContainer>
			</Grid>
		</Section>
		<Section>
			<Grid>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" space="1">
						<Heading size="1">125</Heading>
						<Heading size="5">Companies analyzed</Heading>
					</Stack>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" space="1">
						<Heading size="1">327</Heading>
						<Heading size="5">Strategies planned</Heading>
					</Stack>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" space="1">
						<Heading size="1">224</Heading>
						<Heading size="5">Projects released</Heading>
					</Stack>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" space="1">
						<Heading size="1">856</Heading>
						<Heading size="5">Satisfied clients</Heading>
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
		<Section color="light">
			<Grid>
				<GridContainer size="12 6@sm 4@md">
					<Images width="100%" height="100%" image="theme-wm/home3.jpg" />
				</GridContainer>
				<GridContainer size="12 6@sm 5@md">
					<Card type="base">
						<CardSection>
							<Stack direction="column" align="left" space="3">
								<Heading size="3">Read About Our Work</Heading>
								<Stack direction="column" algin="left" space="1">
									<Paragraph>Class aptent taciti socios qu ad litora torquent per cou bia nosa nostra, per inceptos himenaeos. Maur is in erat justo. Nullamiac urna eu felis dapibus condim en tum sit ame t a augue. Sed non neque elit. Maur is in erat justo. Nullam ac litora lorem ipsum dolor sit amet.</Paragraph>
									<Paragraph>Maur is in erat justo. Nullam ac urna eu felis dapibus condim en tum sit ame t a augue.</Paragraph>
								</Stack>
								<Button label="View More" color="secondary" />
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
				<GridContainer size="12 3@md" align="middle">
					<Stack direction="column" align="left" space="2">
						<Heading size="5">Data &amp; Analysis</Heading>
						<Heading size="5">Operations &amp; Efficiency</Heading>
						<Heading size="5">Digitalization &amp; IT</Heading>
						<Heading size="5">Leadership &amp; Change</Heading>
						<Heading size="5">Growth &amp; Innovation</Heading>
						<Heading size="5">Branding &amp; Design</Heading>
						<Heading size="5">Management &amp; Plan</Heading>
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
		<Section image="theme-wm/marble.jpg">
			<Grid>
				<GridContainer size="12 7@md">
					<Card size="xlarge">
						<CardSection>
							<Heading size="6" uppercase>Our Answers</Heading>
							<Heading size="3">Frequently Asked Questions</Heading>
							<Stack direction="column" align="left" space="2">
								<Stack direction="column" align="left">
									<Accordion label="What types of companies do you work with?" border="none">
										<Paragraph>Class aptent taciti socios qu ad litora torquent per cou bia nostra, per inceptos himenaeos. Maur is in erat justo. Nullam ac urna eu felis dapibus condim en tum sit ame t a augue. Sed non neque elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed</Paragraph>
									</Accordion>
									<Accordion label="Do you write business plans for startups?">
										<Paragraph>Class aptent taciti socios qu ad litora torquent per cou bia nostra, per inceptos himenaeos. Maur is in erat justo. Nullam ac urna eu felis dapibus condim en tum sit ame t a augue. Sed non neque elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed</Paragraph>
									</Accordion>
									<Accordion label="What industries do you specialize in?">
										<Paragraph>Class aptent taciti socios qu ad litora torquent per cou bia nostra, per inceptos himenaeos. Maur is in erat justo. Nullam ac urna eu felis dapibus condim en tum sit ame t a augue. Sed non neque elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed</Paragraph>
									</Accordion>
									<Accordion label="What types of companies do you work with?">
										<Paragraph>Class aptent taciti socios qu ad litora torquent per cou bia nostra, per inceptos himenaeos. Maur is in erat justo. Nullam ac urna eu felis dapibus condim en tum sit ame t a augue. Sed non neque elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed</Paragraph>
									</Accordion>
								</Stack>
								<Button label="View More" color="secondary" />
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
				<GridContainer size="12 5@md">
					<Stack visibility="hide show@md">
						<Images width="100%" height="100%" image="theme-wm/home4.jpg" />
					</Stack>
				</GridContainer>
			</Grid>
		</Section>

	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/windermere/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
